.imgSobreposta{
    position: absolute !important;
    z-index: -1 !important

}
.imgFolha{
    heigth: 900px !important;
    width: 700px!important
}
.ck-editor__editable {
    min-height: 40rem;
}

.selectable-div {
    //display: inline-block;
    //padding: 5px;
    border: 1px solid transparent;
    cursor: pointer;
}
.selectable-div:hover {
    border: 1px solid blue; 
}

@font-face {
  font-family: 'Bahnschript';
  //src: url('/public/media/fontes/bahnschriftcorrect_regular.otf') format('opentype');
  src: url('/public/media/fontes/BahnschriftCorrect-Regular.woff2') format('woff2'),
       url('/public/media/fontes/BahnschriftCorrect-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}