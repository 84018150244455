.divSpinnerTablePlants{
    display: flex; 
    justify-content: center; 
    height: 30rem; 
    align-items: center
}
.spinnerTablePlants{
    width: 3rem; 
    height: 3rem;
}
.divGlobalTitles{
    justify-content: space-between !important;
    //padding: 1rem 0rem 1rem 0rem !important;
}
.divFiltersPlants{
    display: flex !important; 
    gap: 1rem !important; 
    flex-wrap: wrap !important
}
.spanFilter{
    margin-left: 10px !important;
}
.selectFilter{
    width: 100%
}
.buttonFilterMark{
    width: 100%; 
    display: flex; 
    justify-content: space-between; 
    align-items: center
}
.optionButtonFilterMark{
    padding-left: 1rem
}
.myTableResponsive{
    display: flex !important;
    flex-direction: column !important;
}
.divTable{
    min-height: 25rem; 
    max-height: 80vh;
}