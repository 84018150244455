//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system

$white: #ffffff;

// $cor-destaque: #ffc700; // Definindo uma cor padrão SCSS
// @supports (--teste: auto) {
//   $cor-destaque: var(--teste);
// }

// $cor-destaque: red; // Definindo uma cor padrão SCSS
// $primary: $cor-destaque;


//$cor-destaque: var(--teste) ?? red;
//$teste: #c02424;
// Theme colors
// Primary
//$primary: #009ef7;
// @if variable-exists(var(--teste)){
//     $primary: $white;
// }@else{
//     $primary: #ffc700 !default;
// }

//$primary-active: #0095e8;
$primary: #ffc700;
$primary-active: #f1bc00;
//$primary-light: #f1faff;
$primary-light: #f8f8f8;
$primary-light-dark: #212e48;
$primary-inverse: $white;

//Theme Client
// $client-primary: #11f411;
// $client-primary-variant: #f7d664;
// $client-secondary: #1b2431;
// $client-secondary-variant: #34455d;
// $client-active: #f4c111;
// $client-light: #fff8dd;
// $client-light-dark: #392f28;
// $client-inverse: $white;



// Success
$success: #50cd89;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;
