.divLoadingCharts{
    border-radius: 10px !important;
    margin-top: 1.5rem !important;
    height: 20rem !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 0rem !important;
}
.loadingChart{
    width: 3rem !important;
    height: 3rem !important;
}
.divNotCharts{
    border-radius: 10px !important;
    margin-top: 0rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.h3DivNotChart{
    display: flex !important; 
    flex-direction: column !important;
    text-align: center !important ;
}
.divGlobal{
    border-radius: 10px !important;
    margin-top: 0rem !important;
    margin-top: 1.5rem;
}
.divNotGraphics{
    width: 100% !important;
}
.spanNotGraphics{
    font-weight: 800;
}
.divGlobalV2{
    margin-top: 1rem !important;
}
.divGlobalV3{
    gap: 1.5rem;
}
.divGlobalV4{
    flex: 1;
    justify-content: start;
}
.h3Status{
    display: flex;
}
.divClassFilter{
    flex: 1;
    max-width: 20rem;
}
.divGraphics{
    overflow: auto;
    margin-top: 1rem;
}
.divGraphicsV2{
    margin-bottom: 0rem;
}

.divSubInformations{
    margin-top: 0rem !important;
    padding-top: 0rem !important;
    padding-left: 0.5rem !important; 
    padding-right: 0.5rem !important;
}
.divReportGraphics{
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.buttonReport{
    padding: 0.3rem !important;
    display: flex !important;
    align-items: center !important;
}

.divCarousel{
    margin-bottom: 1rem !important;
}
.divCarouselV2{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.divCarouselV3{
    display: flex !important; 
    gap: 2rem !important; 
    justify-content: space-between !important; 
    margin-top: 0rem !important; 
    padding-top: 0rem !important;
}

.h3ItemCarouselV3{
    display: flex !important; 
    flex-direction: column !important; 
    align-items: center !important; 
    justify-content: center !important;
}
.itemH3Carousel{
    display: flex !important; 
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}
.spanItemCarousel{
    width: 100% !important; 
    text-align: center !important;
}
.imgItemCarousel{
    height: 40px !important;
    width: 40px !important;
}
.buttonCarousel{
    width: 1rem !important;
}

@media screen and (max-width: 1200px) {
    .divClassFilter{
        flex: 1 !important;
        max-width: 10rem !important;
    }
}
