.modal-xl{
    width: 80%;
    max-width: 80%;
}
.loadingModalPlant{
    margin-left: 0.5rem
}
.divGlobalHeader{
    display: flex; 
    gap: 1rem;
    flex-wrap: wrap
}
.divGlobalImg{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.divSubImg{
    height: 110px !important;
    width: 110px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.divCardBody{
    display: flex !important;
    align-items: center !important;
}

.divDateModal{
    gap: 2rem;
    width: 100%; 
    justify-content: space-between;
}
.divItemDate{
    display: flex; 
    gap: 1.5rem; 
    flex-direction: column;
}
.divMiniItem{
    flex-direction: column
}
.divMenuOpcoes{
    display: flex !important; 
    gap: 1rem !important; 
    flex-wrap: wrap !important; 
}
.divCardBodyMenu{
    width: 40% !important; 
    padding-top: 2rem !important;
}
.divAddInformations{
    gap: 1.5rem; 
    width: 100%; 
    flex-direction: row;
    justify-content: space-around;
}
.divItemMenu{
    flex-direction: column; 
    gap: 0.5rem; 
    align-items: center;
}
.imgItemMenu{
    width: 60px !important; 
    height: 60px !important;
}
.divItemInformationsDuo{
    flex-direction: row !important;
    gap: 1rem !important;
    justify-content: space-between ;
}
.divTableReport{
    height: 35rem !important;
    overflow: auto !important;
}
.divTableUnitConsumer{
    height: 15rem !important;
    overflow: auto !important;
    width: 100%
}
.divLoading{
    height: 35rem !important;
    display: flex !important; 
    justify-content: center !important;
    align-items: center !important;
}
.loading{
    width: 3rem !important;
    height: 3rem !important;
}