.divSpinnerStatics{
    display: flex !important;
    justify-content: center !important; 
    height: 15rem !important; 
    align-items: center !important;
}
.mySpinnerStatics{
    width: 3rem !important;
    height: 3rem !important;
}
.divGlobalStatistics{
    border-radius: 10px !important;
    margin-right: 0.2rem !important;
    margin-left: 0.2rem !important;
    margin-bottom: 1rem !important;
}
.divTitleStatistics{
    margin-top: 0px !important;
    border-radius: 10px !important;
    height: 15rem !important;
}
.spanTitle{
    font-weight: 900 !important
}
.divItemCarrossel{
    padding-right: 4rem !important; 
    padding-left: 4rem !important;
}

.divCardStatistics{
    margin-top: -9rem !important;
    width: 100% !important;
}

.divCardStatisticsV2{
    width: 100% !important;
    display: flex !important;
    gap: 1rem !important;
}

.divCardUnique{
    flex: 1;
}

.divSubCardStatisticsV2{
    display: flex !important;
    gap: 1rem !important
}


@media screen and (max-width: 1200px) {
    .divGlobalStatistics{
        border-radius: 10px !important;
        margin-right: -10px !important;
        margin-bottom: 0rem !important;
        margin-left: -7px !important;
    }

    .divTitleStatistics{
        margin-top: 0px !important;
        border-radius: 10px !important;
        height: 13rem !important;
        margin-bottom: 0rem !important;
        margin-left: 0px !important;
    }

    .divTitleStatisticsV2{
        padding-left: 0.7rem !important;
        padding-right: 0.7rem !important;
    }

    .divCardStatistics{
        margin-top: -8.5rem !important;
        padding-right: 1.4rem !important;
        padding-left: 1.4rem !important;
    }

    .divCardStatisticsV2{
        display: flex !important;
        justify-content: space-between !important;
        gap: 0.5rem !important;
    }

    .divSubCardsStatistics{
        margin-top: 0.5rem !important;
    }

    .divSubCardStatisticsV2{
        display: flex !important;
        gap: 0.5rem !important;
        justify-content: space-between !important;
    }
    
}

