.divLoadingCharts{
    border-radius: 10px;
    margin-top: 0rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loadingChart{
    width: 3rem;
    height: 3rem;
}
.divNotCharts{
    border-radius: 10px !important;
    margin-top: 0rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.h3DivNotChart{
    display: flex !important; 
    flex-direction: column !important;
    text-align: center !important ;
}
.divGlobal{
    border-radius: 10px;
    margin-top: 0rem;
}


.card-toolbar::-webkit-scrollbar {
    height: 2px; /* Altura da barra de rolagem horizontal */
    background-color: transparent; /* Fundo da barra */

    display: none;
  }
  
  /* Estiliza o polegar (a parte que se move) */
  .card-toolbar::-webkit-scrollbar-thumb {
    background-color: #4caf50; /* Cor do polegar */
    border-radius: 10px; /* Deixa o polegar arredondado */
  }
  
  /* Estiliza a área de rastreamento */
  .card-toolbar::-webkit-scrollbar-track {
    background-color: transparent; /* Fundo da área de rastreamento */
  }