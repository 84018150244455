.seuEstilo1 {
    --size: calc(20 / 100);
    --color: blue;
    color: white;
}
.seuEstilo2 {
    --size: calc(40 / 100);
}
.seuEstilo3 {
    --size: calc(60 / 100);
}
.seuEstilo4 {
    --size: calc(80 / 100);
}

.seuEstilo5 {
    --size: calc(100 / 100);
}