.divClassGraphicsClient{
    padding: 0rem !important;
    /*margin-left: 1rem !important;*/
}

.divClassTablePlants{
    padding: 0rem !important;
}

.divMaps{
    padding: 0rem !important; 
}

.ulGlobal{
    margin-left: 1.2rem;
    margin-right: 0rem;
    justify-content: end;
}

@media screen and (max-width: 1200px) {
    .divClassGraphicsClient {
        padding-right: 0.5rem !important;
        padding-left: 2rem !important;
    }

    .divClassTablePlants{
        padding-right: 1.2rem !important;
        padding-left: 1.3rem !important;
    }

    .divMaps{
        padding-right: 2rem !important; 
        padding-left: 2.5rem !important;
    }
}

