.spanMarcador{
    margin-right: 1rem;
}
.divNotPlants{
    height: 10rem; 
    display: flex; 
    align-items: center; 
    margin-left: 1rem;
}
.disabled td {
    position: relative;
    pointer-events: none;
}

.disabled td::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98%;
    background-color: rgba(169, 169, 169, 0.3);
    pointer-events: none;
  }

  .disabled:hover::before {
    background-color: rgba(169, 169, 169, 0.5);
}