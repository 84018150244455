.spinnerLoadingDashboard{
    display: flex !important; 
    justify-content: center !important; 
    height: 30rem !important; 
    align-items: center !important;
}
.spinnerDashboard{
    width: 3rem !important; 
    height: 3rem !important;
}
.ulGlobal{
    display: flex !important;
    justify-content: right !important;
}

.divGlobal{
    margin-top: 0rem !important;
}

.divGlobalPrincipal{
    padding: 0.5rem !important;
}


@media screen and (max-width: 1200px) {
    .divGlobal {
        margin-top: 1rem !important;
    }

    .divGlobalPrincipal{
        padding: 0rem !important;
    }

}

